














import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export class DragDropTD extends Vue {
  @Prop() question: any;
}
Vue.component("drag-drop-td", DragDropTD);
export default DragDropTD;
