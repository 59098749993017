var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.question.isReadOnlyRenderDiv()
      ? _c("textarea", {
          class: _vm.question.cssClasses
            ? _vm.question.cssClasses.root
            : "panel-comment-root",
          attrs: {
            type: "text",
            readonly: _vm.question.isInputReadOnly,
            disabled: _vm.question.isInputReadOnly,
            id: _vm.question.inputId,
            maxlength: _vm.question.getMaxLength(),
            cols: _vm.question.cols,
            "aria-label": _vm.question.locTitle.renderedHtml,
            rows: _vm.question.rows,
            placeholder: _vm.question.isReadOnly
              ? ""
              : _vm.question.placeHolder,
            "aria-required": _vm.question.isRequired,
            "aria-invalid": _vm.question.errors.length > 0,
            "aria-describedby":
              _vm.question.errors.length > 0
                ? _vm.question.id + "_errors"
                : null
          },
          domProps: { value: _vm.question.value },
          on: { change: _vm.change, keyup: _vm.keyup }
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.question.isReadOnlyRenderDiv()
      ? _c("div", [_vm._v(_vm._s(_vm.question.value))])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }