var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.question.hasVisibleErrors,
          expression: "question.hasVisibleErrors"
        }
      ],
      class: _vm.classes,
      attrs: { role: "alert", id: _vm.question.id + "_errors" }
    },
    _vm._l(_vm.question.errors, function(error) {
      return _c("div", [
        _c("span", {
          class: _vm.question.cssClasses
            ? _vm.question.cssClasses.error.icon
            : "panel-error-icon",
          attrs: { "aria-hidden": "true" }
        }),
        _vm._v(" "),
        _c(
          "span",
          {
            class: _vm.question.cssClasses
              ? _vm.question.cssClasses.error.item
              : "panel-error-item"
          },
          [_c("survey-string", { attrs: { locString: error.locText } })],
          1
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }