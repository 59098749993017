































import Vue from "vue";
import { Prop, Component } from "vue-property-decorator";
import { AdaptiveActionBarItemWrapper } from "survey-core";
import { Base } from "survey-core";
import BaseVue from "../../base";

@Component
export class ActionBarItemViewModel extends BaseVue {
  @Prop() public item: AdaptiveActionBarItemWrapper;

  getModel(): Base {
    return this.item.stateItem;
  }
}

Vue.component("sv-action-bar-item", ActionBarItemViewModel);
export default ActionBarItemViewModel;
