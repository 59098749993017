




import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { default as QuestionVue } from "./question";
import { QuestionCompositeModel } from "survey-core";
import { PanelModel } from "../panel";

@Component
export class Composite extends QuestionVue<QuestionCompositeModel> {
  get contentPanel(): PanelModel {
    return this.question.contentPanel;
  }
}
Vue.component("survey-composite", Composite);

export default Composite;
