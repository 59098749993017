var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.getRootClass(_vm.element) },
    [
      !_vm.element.isPanel && _vm.element.hasTitleOnLeftTop
        ? _c("survey-element-header", {
            attrs: { element: _vm.element, css: _vm.css }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.element.isPanel || !_vm.element.isCollapsed,
              expression: "element.isPanel || !element.isCollapsed"
            }
          ],
          class: _vm.getContentClass(_vm.element)
        },
        [
          !_vm.element.isPanel && _vm.hasErrorsOnTop
            ? _c("survey-errors", {
                attrs: { question: _vm.element, location: "top" }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(_vm.getComponentName(_vm.element), {
            tag: "component",
            attrs: { question: _vm.element, css: _vm.css }
          }),
          _vm._v(" "),
          _vm.element.hasComment
            ? _c(
                "div",
                { class: _vm.element.cssClasses.formGroup },
                [
                  _c("div", [_vm._v(_vm._s(_vm.element.commentText))]),
                  _vm._v(" "),
                  _c("survey-other-choice", {
                    attrs: {
                      commentClass: _vm.css.comment,
                      question: _vm.element
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.element.isPanel && _vm.hasErrorsOnBottom
            ? _c("survey-errors", {
                attrs: { question: _vm.element, location: "bottom" }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.element.isPanel && _vm.element.hasDescriptionUnderInput
            ? _c(
                "div",
                { class: _vm.element.cssClasses.descriptionUnderInput },
                [
                  _c("survey-string", {
                    attrs: { locString: _vm.element.locDescription }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      !_vm.element.isPanel && _vm.element.hasTitleOnBottom
        ? _c("survey-element-header", {
            attrs: { element: _vm.element, css: _vm.css }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }