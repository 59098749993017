
























































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { default as QuestionVue } from "./question";
import { QuestionFileModel } from "survey-core";
import { confirmAction, detectIEOrEdge, loadFileFromBase64 } from "survey-core";
@Component
export class File extends QuestionVue<QuestionFileModel> {
  onDragOver = (event: any) => {
    if (this.question.isReadOnly) {
      event.returnValue = false;
      return false;
    }
    event.dataTransfer.dropEffect = "copy";
    event.preventDefault();
  };
  onDrop = (event: any) => {
    event.preventDefault();
    let src = event.dataTransfer;
    this.onChange(src);
  };
  doChange(event: any) {
    var src = event.target || event.srcElement;
    this.onChange(src);
  }
  doClean(event: any) {
    var question = this.question;
    var src = event.target || event.srcElement;
    if (question.needConfirmRemoveFile) {
      var isConfirmed = confirmAction(question.confirmRemoveAllMessage);
      if (!isConfirmed) return;
    }
    question.clear();
    src.parentElement.querySelectorAll("input")[0].value = "";
  }
  doRemoveFile(data: any) {
    var question = this.question;
    if (question.needConfirmRemoveFile) {
      var isConfirmed = confirmAction(
        question.getConfirmRemoveMessage(data.name)
      );
      if (!isConfirmed) return;
    }
    question.removeFile(data);
  }
  doDownloadFile(event: any, data: any) {
    if (detectIEOrEdge()) {
      event.preventDefault();
      loadFileFromBase64(data.content, data.name);
    }
  }
  getPlaceholderClass() {
    return "form-control " + this.question.cssClasses.placeholderInput;
  }
  private onChange(src: any) {
    if (!(<any>window)["FileReader"]) return;
    if (!src || !src.files || src.files.length < 1) return;
    let files = [];
    let allowCount = this.question.allowMultiple ? src.files.length : 1;
    for (let i = 0; i < allowCount; i++) {
      files.push(src.files[i]);
    }
    src.value = "";
    this.question.loadFiles(files);
  }
}
Vue.component("survey-file", File);
export default File;
