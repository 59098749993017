



















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { SurveyModel } from "survey-core";

@Component
export class Progress extends Vue {
  @Prop() survey: SurveyModel;
  @Prop() css: any;
  get progress() {
    return this.survey.progressValue + "%";
  }
}
Vue.component("sv-progress-pages", Progress);
Vue.component("sv-progress-questions", Progress);
Vue.component("sv-progress-correctQuestions", Progress);
Vue.component("sv-progress-requiredQuestions", Progress);
export default Progress;
