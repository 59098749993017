var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return (_vm.survey.locTitle.renderedHtml.length > 0 &&
    _vm.survey.showTitle) ||
    _vm.survey.hasLogo
    ? _c("div", { class: _vm.survey.css.header }, [
        _vm.survey.isLogoBefore
          ? _c("div", { class: _vm.survey.logoClassNames }, [
              _c("img", {
                class: _vm.survey.css.logoImage,
                style: { objectFit: _vm.survey.logoFit },
                attrs: {
                  src: _vm.survey.locLogo.renderedHtml,
                  width: _vm.survey.logoWidth
                    ? _vm.survey.logoWidth
                    : undefined,
                  height: _vm.survey.logoHeight
                    ? _vm.survey.logoHeight
                    : undefined,
                  alt: _vm.survey.locTitle.renderedHtml
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.survey.locTitle.renderedHtml.length > 0 && _vm.survey.showTitle
          ? _c(
              "div",
              {
                class: _vm.survey.css.headerText,
                style: { maxWidth: _vm.survey.titleMaxWidth }
              },
              [
                _c(
                  "h3",
                  { class: _vm.survey.css.title },
                  [
                    _c("survey-string", {
                      attrs: { locString: _vm.survey.locTitle }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "h5",
                  { class: _vm.survey.css.description },
                  [
                    _c("survey-string", {
                      attrs: { locString: _vm.survey.locDescription }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.survey.isLogoAfter
          ? _c("div", { class: _vm.survey.logoClassNames }, [
              _c("img", {
                class: _vm.survey.css.logoImage,
                style: { objectFit: _vm.survey.logoFit },
                attrs: {
                  src: _vm.survey.locLogo.renderedHtml,
                  width: _vm.survey.logoWidth
                    ? _vm.survey.logoWidth
                    : undefined,
                  height: _vm.survey.logoHeight
                    ? _vm.survey.logoHeight
                    : undefined,
                  alt: _vm.survey.locTitle.renderedHtml
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.survey.isLogoAfter
          ? _c("div", { staticClass: "sv-logo--right-tail" })
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }