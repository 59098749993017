var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.question.cssClasses.root }, [
    _c("label", { class: _vm.itemClass }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.question.checkedValue,
            expression: "question.checkedValue"
          }
        ],
        class: _vm.question.cssClasses.control,
        attrs: {
          type: "checkbox",
          name: _vm.question.name,
          id: _vm.question.inputId,
          disabled: _vm.question.isInputReadOnly,
          "aria-required": _vm.question.isRequired,
          "aria-label": _vm.question.locTitle.renderedHtml,
          "aria-invalid": _vm.question.errors.length > 0,
          "aria-describedby":
            _vm.question.errors.length > 0 ? _vm.question.id + "_errors" : null
        },
        domProps: {
          value: _vm.question.checkedValue,
          indeterminate: _vm.question.isIndeterminate,
          checked: Array.isArray(_vm.question.checkedValue)
            ? _vm._i(_vm.question.checkedValue, _vm.question.checkedValue) > -1
            : _vm.question.checkedValue
        },
        on: {
          change: function($event) {
            var $$a = _vm.question.checkedValue,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = _vm.question.checkedValue,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 &&
                  _vm.$set(_vm.question, "checkedValue", $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  _vm.$set(
                    _vm.question,
                    "checkedValue",
                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                  )
              }
            } else {
              _vm.$set(_vm.question, "checkedValue", $$c)
            }
          }
        }
      }),
      _vm._v(" "),
      _c("span", { class: _vm.question.cssClasses.materialDecorator }, [
        _c(
          "svg",
          {
            class: _vm.question.cssClasses.itemDecorator,
            attrs: { viewBox: "0 0 24 24" }
          },
          [
            _c("rect", {
              class: _vm.question.cssClasses.uncheckedPath,
              attrs: { x: "5", y: "10", width: "14", height: "4" }
            }),
            _vm._v(" "),
            _c("polygon", {
              class: _vm.question.cssClasses.checkedPath,
              attrs: {
                points:
                  "19,10 14,10 14,5 10,5 10,10 5,10 5,14 10,14 10,19 14,19 14,14 19,14 "
              }
            }),
            _vm._v(" "),
            _c("path", {
              class: _vm.question.cssClasses.indeterminatePath,
              attrs: {
                d:
                  "M22,0H2C0.9,0,0,0.9,0,2v20c0,1.1,0.9,2,2,2h20c1.1,0,2-0.9,2-2V2C24,0.9,23.1,0,22,0z M21,18L6,3h15V18z M3,6l15,15H3V6z"
              }
            })
          ]
        ),
        _vm._v(" "),
        _c("span", { staticClass: "check" })
      ]),
      _vm._v(" "),
      _vm.question.titleLocation === "hidden"
        ? _c(
            "span",
            { class: _vm.question.cssClasses.label },
            [
              _c("survey-string", {
                attrs: { locString: _vm.question.locDisplayLabel }
              })
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }