

















































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { default as QuestionVue } from "./question";
import { QuestionBooleanModel } from "survey-core";
import { Boolean } from "./boolean";
import { RendererFactory } from "survey-core";

@Component
export class BooleanCheckbox extends Boolean {}

Vue.component("sv-boolean-checkbox", BooleanCheckbox);

RendererFactory.Instance.registerRenderer(
  "boolean",
  "checkbox",
  "sv-boolean-checkbox"
);

export default BooleanCheckbox;
