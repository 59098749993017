var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.question.cssClasses.root },
    [
      !_vm.question.isReadOnly
        ? _c("div", { class: _vm.question.cssClasses.selectWrapper }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.question.renderedValue,
                    expression: "question.renderedValue"
                  }
                ],
                class: _vm.question.getControlClass(),
                attrs: {
                  id: _vm.question.inputId,
                  autocomplete: _vm.question.autoComplete,
                  "aria-label": _vm.question.locTitle.renderedHtml,
                  "aria-invalid": _vm.question.errors.length > 0,
                  "aria-describedby":
                    _vm.question.errors.length > 0
                      ? _vm.question.id + "_errors"
                      : null
                },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.question,
                      "renderedValue",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _vm.question.showOptionsCaption
                  ? _c("option", { domProps: { value: undefined } }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.question.optionsCaption) +
                          "\n      "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.question.visibleChoices, function(item) {
                  return _c(
                    "option",
                    {
                      attrs: { disabled: !item.isEnabled },
                      domProps: { value: item.value }
                    },
                    [_vm._v("\n        " + _vm._s(item.text) + "\n      ")]
                  )
                })
              ],
              2
            )
          ])
        : _c(
            "div",
            {
              class: _vm.question.getControlClass(),
              attrs: { disabled: "", id: _vm.question.inputId }
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.isOtherSelected
                      ? _vm.question.otherText
                      : _vm.question.displayValue
                  ) +
                  "\n  "
              )
            ]
          ),
      _vm._v(" "),
      _c("survey-other-choice", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isOtherSelected,
            expression: "isOtherSelected"
          }
        ],
        attrs: { question: _vm.question }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }