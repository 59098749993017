var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sv-vue-row-additional-div" },
    _vm._l(_vm.row.elements, function(element) {
      return _vm.row.isNeedRender && element.isVisible
        ? _c(
            "div",
            {
              key: element.idValue,
              style: {
                flexBasis: element.renderWidth,
                flexGrow: 1,
                flexShrink: 1,
                width: element.renderWidth,
                minWidth: element.minWidth,
                maxWidth: element.maxWidth
              }
            },
            [
              _c("survey-element", {
                style: {
                  paddingLeft: element.paddingLeft,
                  paddingRight: element.paddingRight
                },
                attrs: {
                  id: element.id,
                  role: element.ariaRole,
                  "aria-labelledby": element.hasTitle
                    ? element.ariaTitleId
                    : null,
                  name: element.name,
                  element: element,
                  survey: _vm.survey,
                  css: _vm.css
                }
              })
            ],
            1
          )
        : _vm._e()
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }