

































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { PanelModelBase, PanelModel, QuestionRowModel } from "survey-core";
import { ISurvey, Base } from "survey-core";
import { BaseVue } from "./base";

@Component
export class Panel extends BaseVue {
  @Prop() question: PanelModel;
  @Prop() isEditMode: Boolean;
  @Prop() css: any;

  private isCollapsed: boolean = false;

  protected getModel(): Base {
    return this.question;
  }
  protected onMounted() {
    if (this.question.survey) {
      this.question.survey.afterRenderPanel(
        this.question,
        this.$el as HTMLElement
      );
    }
    this.isCollapsed = this.question.isCollapsed;

    this.question.stateChangedCallback = () => {
      this.isCollapsed = this.question.isCollapsed;
    };
  }
  beforeDestroy() {
    this.question.stateChangedCallback = null;
  }
  get rootStyle() {
    var result = {};
    if (this.question.renderWidth) {
      (<any>result)["flexBasis"] = this.question.renderWidth;
      (<any>result)["flexGrow"] = 1;
      (<any>result)["flexShrink"] = 1;
      (<any>result)["width"] = this.question.renderWidth;
      (<any>result)["minWidth"] = this.question.minWidth;
      (<any>result)["maxWidth"] = this.question.maxWidth;
    }
    return result;
  }
  get showIcon() {
    return this.question.isExpanded || this.question.isCollapsed;
  }
  get rows() {
    return this.question.rows;
  }
  get hasTitle() {
    return this.question.title.length > 0;
  }
  get survey() {
    return this.question.survey;
  }
  get iconCss() {
    var result = this.css.panel.icon;
    if (!this.isCollapsed) result += " " + this.css.panel.iconExpanded;
    return result;
  }
  changeExpanded() {
    this.question.toggleState();
  }
  cancelPreview() {
    this.question.cancelPreview();
  }
  getTitleStyle() {
    var result = this.css.panel.title;
    if (this.question.isCollapsed || this.question.isExpanded) {
      result += " " + this.css.panel.titleExpandable;
    }
    if (this.question.containsErrors) {
      result += " " + this.question.cssClasses.panel.titleOnError;
    }
    return result;
  }

  get requiredTextCss() {
    return (
      this.question.cssClasses.requiredText ||
      this.question.cssClasses.panel.requiredText
    );
  }
}
Vue.component("survey-panel", Panel);
export default Panel;
