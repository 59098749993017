var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.css.footer }, [
    _c("input", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showPrevBtn,
          expression: "showPrevBtn"
        }
      ],
      class: _vm.survey.cssNavigationPrev,
      attrs: { type: "button", value: _vm.survey.pagePrevText },
      on: { mousedown: _vm.buttonMouseDown, click: _vm.prevPage }
    }),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showNextBtn,
          expression: "showNextBtn"
        }
      ],
      class: _vm.survey.cssNavigationNext,
      attrs: { type: "button", value: _vm.survey.pageNextText },
      on: { mousedown: _vm.nextButtonMouseDown, click: _vm.nextPage }
    }),
    _vm._v(" "),
    _vm.survey.isPreviewButtonVisible
      ? _c("input", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.survey.isLastPage,
              expression: "survey.isLastPage"
            }
          ],
          class: _vm.survey.cssNavigationPreview,
          attrs: { type: "button", value: _vm.survey.previewText },
          on: { mousedown: _vm.buttonMouseDown, click: _vm.showPreview }
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.survey.isCompleteButtonVisible
      ? _c("input", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showCompleteBtn,
              expression: "showCompleteBtn"
            }
          ],
          class: _vm.survey.cssNavigationComplete,
          attrs: { type: "button", value: _vm.survey.completeText },
          on: { mousedown: _vm.buttonMouseDown, click: _vm.completeLastPage }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }