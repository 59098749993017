var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class:
        _vm.question.cssClasses.button +
        " " +
        _vm.question.cssClasses.buttonRemove,
      attrs: { type: "button", disabled: _vm.question.isInputReadOnly },
      on: {
        click: function() {
          return _vm.question.removeRowUI(_vm.row)
        }
      }
    },
    [
      _c("survey-string", {
        attrs: { locString: _vm.question.locRemoveRowText }
      }),
      _vm._v(" "),
      _c("span", { class: _vm.question.cssClasses.iconRemove })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }