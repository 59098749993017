var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "td",
    {
      class: _vm.cell.className,
      style: _vm.getCellStyle(),
      attrs: { title: _vm.getHeaders(), colspan: _vm.cell.colSpans }
    },
    [
      _vm.cell.isActionsCell
        ? _c("sv-action-bar", {
            attrs: { items: _vm.cell.item.getData(), handleClick: false }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.cell.hasPanel
        ? _c(_vm.getComponentName(_vm.cell.panel), {
            tag: "component",
            attrs: { question: _vm.cell.panel, css: _vm.question.survey.css }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.cell.hasQuestion
        ? _c(
            "div",
            [
              _vm.hasErrorsOnTop
                ? _c("survey-errors", {
                    attrs: { question: _vm.cell.question, location: "top" }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.cell.isChoice && _vm.cell.question.isDefaultRendering()
                ? _c(_vm.getComponentName(_vm.cell.question), {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isVisible,
                        expression: "isVisible"
                      }
                    ],
                    tag: "component",
                    attrs: { question: _vm.cell.question }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.cell.isChoice && !_vm.cell.question.isDefaultRendering()
                ? _c(_vm.cell.question.getComponentName(), {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isVisible,
                        expression: "isVisible"
                      }
                    ],
                    tag: "component",
                    attrs: { question: _vm.cell.question }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.cell.isChoice && !_vm.cell.isCheckbox
                ? _c("survey-radiogroup-item", {
                    key: _vm.cell.item.value,
                    class: _vm.getItemClass(_vm.cell.item),
                    attrs: {
                      question: _vm.cell.question,
                      item: _vm.cell.item,
                      index: "" + _vm.cell.index,
                      hideLabel: true
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.cell.isChoice && _vm.cell.isCheckbox
                ? _c("survey-checkbox-item", {
                    key: _vm.cell.item.value,
                    class: _vm.getItemClass(_vm.cell.item),
                    attrs: {
                      question: _vm.cell.question,
                      item: _vm.cell.item,
                      index: "" + _vm.cell.index,
                      hideLabel: true
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.hasErrorsOnBottom
                ? _c("survey-errors", {
                    attrs: { question: _vm.cell.question, location: "bottom" }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.cell.hasTitle
        ? _c("survey-string", { attrs: { locString: _vm.cell.locTitle } })
        : _vm._e(),
      _vm._v(" "),
      !!_vm.cell.requiredText
        ? _c("span", [_vm._v(_vm._s(_vm.cell.requiredText))])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }