var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.question.cssClasses.root }, [
    _c("label", { class: _vm.itemClass }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.question.checkedValue,
            expression: "question.checkedValue"
          }
        ],
        class: _vm.question.cssClasses.control,
        attrs: {
          type: "checkbox",
          name: _vm.question.name,
          id: _vm.question.inputId,
          disabled: _vm.question.isInputReadOnly,
          "aria-required": _vm.question.isRequired,
          "aria-label": _vm.question.locTitle.renderedHtml,
          "aria-invalid": _vm.question.errors.length > 0,
          "aria-describedby":
            _vm.question.errors.length > 0 ? _vm.question.id + "_errors" : null
        },
        domProps: {
          value: _vm.question.checkedValue,
          indeterminate: _vm.question.isIndeterminate,
          checked: Array.isArray(_vm.question.checkedValue)
            ? _vm._i(_vm.question.checkedValue, _vm.question.checkedValue) > -1
            : _vm.question.checkedValue
        },
        on: {
          change: function($event) {
            var $$a = _vm.question.checkedValue,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = _vm.question.checkedValue,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 &&
                  _vm.$set(_vm.question, "checkedValue", $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  _vm.$set(
                    _vm.question,
                    "checkedValue",
                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                  )
              }
            } else {
              _vm.$set(_vm.question, "checkedValue", $$c)
            }
          }
        }
      }),
      _vm._v(" "),
      _c(
        "span",
        {
          class: _vm.getLabelClass(false),
          on: {
            click: function($event) {
              return _vm.onLabelClick($event, false)
            }
          }
        },
        [
          _c("survey-string", {
            attrs: { locString: _vm.question.locLabelFalse }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: _vm.question.cssClasses.switch,
          on: {
            click: function($event) {
              return _vm.onSwitchClick($event)
            }
          }
        },
        [_c("span", { class: _vm.question.cssClasses.slider })]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          class: _vm.getLabelClass(true),
          on: {
            click: function($event) {
              return _vm.onLabelClick($event, true)
            }
          }
        },
        [
          _c("survey-string", {
            attrs: { locString: _vm.question.locLabelTrue }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }