var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.getComponentName(_vm.contentQuestion), {
    tag: "component",
    attrs: { question: _vm.contentQuestion, css: _vm.css }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }