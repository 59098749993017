































import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { IElement } from "survey-core";

@Component
export class TitleContent extends Vue {
  @Prop() element: IElement;
  @Prop() css: any;
}
Vue.component("survey-question-title-content", TitleContent);
export default TitleContent;
