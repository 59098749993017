var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.getHeaderClass(_vm.element),
      on: {
        click: function() {
          if (_vm.element.hasInput) {
            _vm.element.focus()
          }
          return true
        }
      }
    },
    [
      _vm.element.hasTitle
        ? _c(
            "h5",
            {
              class: _vm.getTitleClass(_vm.element),
              attrs: {
                "aria-label": _vm.element.locTitle.renderedHtml,
                id: _vm.element.ariaTitleId
              },
              on: {
                click: function() {
                  _vm.element.toggleState()
                }
              }
            },
            [
              _c(_vm.element.getTitleComponentName(), {
                tag: "component",
                attrs: { element: _vm.element, css: _vm.css }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.element.hasDescriptionUnderTitle
        ? _c(
            "div",
            { class: _vm.element.cssClasses.description },
            [
              _c("survey-string", {
                attrs: { locString: _vm.element.locDescription }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }