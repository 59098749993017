














































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { PanelModel } from "survey-core";
import { QuestionPanelDynamicModel } from "survey-core";
import { Question } from "survey-core";

@Component
export class PanelDynamicProgress extends Vue {
  @Prop() question: QuestionPanelDynamicModel;

  get cssClass() {
    return this.question.isProgressTopShowing
      ? this.question.cssClasses.progressTop
      : this.question.cssClasses.progressBottom;
  }
  get rangeMax() {
    return this.question.panelCount - 1;
  }
  addPanelClick() {
    this.question.addPanelUI();
  }
  prevPanelClick() {
    this.question.goToPrevPanel();
  }
  nextPanelClick() {
    this.question.goToNextPanel();
  }

  changeRange(event: any) {
    this.question.currentIndex = event.target.value;
  }

  getButtonAddCss(question: Question) {}

  getButtonPrevCss(question: Question) {
    var btnClasses = question.cssClasses.buttonPrev;
    if (!question.isPrevButtonShowing) {
      btnClasses += " " + question.cssClasses.buttonPrev + "--disabled";
    }
    return btnClasses;
  }

  getButtonNextCss(question: Question) {
    var btnClasses = question.cssClasses.buttonNext;
    if (!question.isNextButtonShowing) {
      btnClasses += " " + question.cssClasses.buttonNext + "--disabled";
    }
    return btnClasses;
  }

  get progress() {
    return (this.question.currentIndex / this.rangeMax) * 100 + "%";
  }
}

Vue.component("survey-paneldynamicprogress", PanelDynamicProgress);
export default PanelDynamicProgress;
