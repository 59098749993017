






























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { default as QuestionVue } from "./question";
import { PanelModel } from "survey-core";
import { Question } from "survey-core";
import { QuestionPanelDynamicModel } from "survey-core";

@Component
export class PanelDynamic extends QuestionVue<QuestionPanelDynamicModel> {
  get renderedPanels() {
    if (this.question.isRenderModeList) return this.question.panels;
    var panels = [];
    if (this.question.currentPanel) {
      panels.push(this.question.currentPanel);
    }
    return panels;
  }
  addPanelClick() {
    this.question.addPanel();
  }
  getButtonAddCss(question: Question) {
    var btnClasses =
      question.cssClasses.button + " " + question.cssClasses.buttonAdd;

    if (this.question.renderMode === "list") {
      btnClasses += " " + question.cssClasses.buttonAdd + "--list-mode";
    }

    return btnClasses;
  }
}
Vue.component("survey-paneldynamic", PanelDynamic);
export default PanelDynamic;
