var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { overflowX: _vm.question.horizontalScroll ? "scroll" : "" } },
    [
      _vm.question.renderedTable.showAddRowOnTop
        ? _c("div", { class: _vm.question.cssClasses.footer }, [
            _c(
              "button",
              {
                class:
                  _vm.question.cssClasses.button +
                  " " +
                  _vm.question.cssClasses.buttonAdd,
                attrs: { type: "button" },
                on: { click: _vm.addRowClick }
              },
              [
                _c("survey-string", {
                  attrs: { locString: _vm.question.locAddRowText }
                }),
                _vm._v(" "),
                _c("span", { class: _vm.question.cssClasses.iconAdd })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.question.renderedTable.showTable
        ? _c("survey-matrixtable", { attrs: { question: _vm.question } })
        : _vm._e(),
      _vm._v(" "),
      !_vm.question.renderedTable.showTable
        ? _c("div", { class: _vm.question.cssClasses.emptyRowsSection }, [
            _c(
              "div",
              { class: _vm.question.cssClasses.emptyRowsText },
              [
                _c("survey-string", {
                  attrs: { locString: _vm.question.locEmptyRowsText }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                class:
                  _vm.question.cssClasses.button +
                  " " +
                  _vm.question.cssClasses.buttonAdd +
                  " " +
                  _vm.question.cssClasses.emptyRowsButton,
                attrs: { type: "button" },
                on: { click: _vm.addRowClick }
              },
              [
                _c("survey-string", {
                  attrs: { locString: _vm.question.locAddRowText }
                }),
                _vm._v(" "),
                _c("span", { class: _vm.question.cssClasses.iconAdd })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.question.renderedTable.showAddRowOnBottom
        ? _c("div", { class: _vm.question.cssClasses.footer }, [
            _c(
              "button",
              {
                class:
                  _vm.question.cssClasses.button +
                  " " +
                  _vm.question.cssClasses.buttonAdd,
                attrs: { type: "button" },
                on: { click: _vm.addRowClick }
              },
              [
                _c("survey-string", {
                  attrs: { locString: _vm.question.locAddRowText }
                }),
                _vm._v(" "),
                _c("span", { class: _vm.question.cssClasses.iconAdd })
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }