

















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ISurveyElement } from "survey-core";
import { RendererFactory } from "survey-core";

@Component
export class TitleActions extends Vue {
  @Prop() element: ISurveyElement;
  @Prop() css: any;
  constructor() {
    super();
  }

  public get items() {
    return this.element.getTitleActions();
  }
}

RendererFactory.Instance.registerRenderer(
  "element",
  "title-actions",
  "sv-title-actions"
);

Vue.component("sv-title-actions", TitleActions);
export default TitleActions;
