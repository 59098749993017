var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      staticClass: "sv-list",
      on: {
        mousedown: function(data, e) {
          e.preventDefault()
        }
      }
    },
    _vm._l(_vm.model.items, function(item) {
      return _c(
        "li",
        {
          key: item.id,
          staticClass: "sv-list__item",
          class: _vm.model.getItemClass(item),
          on: {
            click: function($event) {
              return _vm.model.selectItem(item)
            }
          }
        },
        [
          item.iconName
            ? _c("sv-svg-icon", {
                staticClass: "sv-list__item-icon",
                attrs: { iconName: item.iconName, size: 24 }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(item.title))])
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }