var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("label", { class: _vm.getLabelClass(_vm.item) }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.question.renderedValue,
              expression: "question.renderedValue"
            }
          ],
          class: _vm.question.cssClasses.itemControl,
          attrs: {
            type: "radio",
            name: _vm.question.name + "_" + _vm.question.id,
            id: _vm.question.inputId + "_" + _vm.index,
            disabled: _vm.question.isInputReadOnly || !_vm.item.isEnabled,
            "aria-required": _vm.question.isRequired,
            "aria-label": _vm.item.locText.renderedHtml,
            "aria-invalid": _vm.question.errors.length > 0,
            "aria-describedby":
              _vm.question.errors.length > 0
                ? _vm.question.id + "_errors"
                : null
          },
          domProps: {
            value: _vm.item.value,
            checked: _vm._q(_vm.question.renderedValue, _vm.item.value)
          },
          on: {
            change: function($event) {
              return _vm.$set(_vm.question, "renderedValue", _vm.item.value)
            }
          }
        }),
        _vm._v(" "),
        _c("span", { class: _vm.question.cssClasses.materialDecorator }, [
          _c(
            "svg",
            {
              class: _vm.question.cssClasses.itemDecorator,
              attrs: { viewBox: "-12 -12 24 24" }
            },
            [_c("circle", { attrs: { r: "6", cx: "0", cy: "0" } })]
          )
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "check" }),
        _vm._v(" "),
        !_vm.hideLabel
          ? _c(
              "span",
              {
                class: _vm.getControlLabelClass(_vm.item),
                attrs: { title: _vm.item.locText.text }
              },
              [_c("survey-string", { attrs: { locString: _vm.item.locText } })],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.item.value == _vm.question.otherItem.value
        ? _c("survey-other-choice", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.question.hasOther &&
                  _vm.question.renderedValue &&
                  _vm.question.isOtherSelected,
                expression:
                  "\n      question.hasOther && question.renderedValue && question.isOtherSelected\n    "
              }
            ],
            attrs: { question: _vm.question }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }