var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.css.root }, [
    _c("form", { attrs: { onsubmit: "return false;" } }, [
      !_vm.survey.hasLogo
        ? _c("div", { staticClass: "sv_custom_header" })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.css.container },
        [
          _c("survey-header", { attrs: { survey: _vm.survey } }),
          _vm._v(" "),
          _vm.survey.state === "starting"
            ? [
                _c(
                  "div",
                  { class: _vm.css.body },
                  [
                    _vm.survey.isNavigationButtonsShowingOnTop
                      ? _c("div", { class: _vm.css.footer }, [
                          _c("input", {
                            class: _vm.survey.cssNavigationStart,
                            attrs: {
                              type: "button",
                              value: _vm.survey.startSurveyText
                            },
                            on: { click: _vm.start }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("survey-page", {
                      attrs: {
                        id: _vm.survey.startedPage.id,
                        survey: _vm.survey,
                        page: _vm.survey.startedPage,
                        css: _vm.css
                      }
                    }),
                    _vm._v(" "),
                    _vm.survey.isNavigationButtonsShowingOnBottom
                      ? _c("div", { class: _vm.css.footer }, [
                          _c("input", {
                            class: _vm.survey.cssNavigationStart,
                            attrs: {
                              type: "button",
                              value: _vm.survey.startSurveyText
                            },
                            on: { click: _vm.start }
                          })
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.survey.state === "running" || _vm.survey.state === "preview"
            ? [
                _c(
                  "div",
                  { class: _vm.css.body },
                  [
                    _vm.survey.isShowProgressBarOnTop
                      ? _c(
                          "sv-progress-" +
                            _vm.survey.progressBarType.toLowerCase(),
                          {
                            tag: "component",
                            attrs: { survey: _vm.survey, css: _vm.css }
                          }
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.survey.isTimerPanelShowingOnTop
                      ? _c("survey-timerpanel", {
                          attrs: { survey: _vm.survey, css: _vm.css }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.survey.isNavigationButtonsShowingOnTop
                      ? _c("survey-navigation", {
                          key: _vm.navId,
                          attrs: { survey: _vm.survey, css: _vm.css }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("survey-page", {
                      key: _vm.pageId,
                      attrs: {
                        survey: _vm.survey,
                        page: _vm.survey.currentPage,
                        css: _vm.css
                      }
                    }),
                    _vm._v(" "),
                    _vm.survey.isTimerPanelShowingOnBottom
                      ? _c("survey-timerpanel", {
                          attrs: { survey: _vm.survey, css: _vm.css }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.survey.isShowProgressBarOnBottom
                      ? _c(
                          "sv-progress-" +
                            _vm.survey.progressBarType.toLowerCase(),
                          {
                            tag: "component",
                            attrs: { survey: _vm.survey, css: _vm.css }
                          }
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.survey.isNavigationButtonsShowingOnBottom
                      ? _c("survey-navigation", {
                          key: _vm.navId,
                          attrs: { survey: _vm.survey, css: _vm.css }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.hasCompletedPage
            ? _c("div", [
                _c("div", {
                  class: _vm.getCompletedPageClasses(),
                  domProps: {
                    innerHTML: _vm._s(_vm.getProcessedCompletedHtml())
                  }
                }),
                _vm._v(" "),
                _vm.survey.completedState != ""
                  ? _c("div", { class: _vm.css.saveData.root }, [
                      _c("div", { class: _vm.getCompletedStateClasses() }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.survey.completedStateText))
                        ]),
                        _vm._v(" "),
                        _vm.survey.completedState == "error"
                          ? _c("input", {
                              class: _vm.css.saveData.saveAgainButton,
                              attrs: {
                                type: "button",
                                value: _vm.survey.getLocString(
                                  "saveAgainButton"
                                )
                              },
                              on: { click: _vm.doTrySaveAgain }
                            })
                          : _vm._e()
                      ])
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.survey.state === "completedbefore"
            ? _c("div", {
                class: _vm.css.body,
                domProps: {
                  innerHTML: _vm._s(_vm.survey.processedCompletedBeforeHtml)
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.survey.state === "loading"
            ? _c("div", {
                class: _vm.css.body,
                domProps: { innerHTML: _vm._s(_vm.survey.processedLoadingHtml) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.survey.state === "empty"
            ? _c("div", { class: _vm.css.bodyEmpty }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.survey.emptySurveyText) + "\n      "
                )
              ])
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }