var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.surveyWindow.isShowing,
          expression: "surveyWindow.isShowing"
        }
      ],
      class: _vm.css.window.root,
      staticStyle: {
        position: "fixed",
        bottom: "3px",
        right: "10px",
        "max-width": "60%"
      }
    },
    [
      _c("div", { class: _vm.css.window.header.root }, [
        _c(
          "span",
          {
            staticStyle: { width: "100%", cursor: "pointer" },
            on: { click: _vm.doExpand }
          },
          [
            _c(
              "span",
              {
                class: _vm.css.window.header.title,
                staticStyle: { "padding-right": "10px" }
              },
              [
                _c("survey-string", {
                  attrs: { locString: _vm.windowSurvey.locTitle }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("span", {
              class: _vm.expandedCss,
              attrs: { "aria-hidden": "true" }
            })
          ]
        ),
        _vm._v(" "),
        _vm.isExpandedSurvey
          ? _c(
              "span",
              {
                staticStyle: { float: "right", cursor: "pointer" },
                on: { click: _vm.doExpand }
              },
              [
                _c(
                  "span",
                  {
                    class: _vm.css.window.header.title,
                    staticStyle: { "padding-right": "10px" }
                  },
                  [_vm._v("X")]
                )
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isExpandedSurvey,
              expression: "isExpandedSurvey"
            }
          ],
          class: _vm.css.window.body
        },
        [_c("survey", { attrs: { survey: _vm.windowSurvey } })],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }