var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.question.isVisible
    ? _c(
        "div",
        {
          class: _vm.question.cssClasses.panel.container,
          style: _vm.rootStyle
        },
        [
          _c(
            "h4",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasTitle,
                  expression: "hasTitle"
                }
              ],
              class: _vm.getTitleStyle(),
              on: { click: _vm.changeExpanded }
            },
            [
              _vm.question.isRequireTextOnStart
                ? _c("span", { class: _vm.requiredTextCss }, [
                    _vm._v(_vm._s(_vm.question.requiredText))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.question.no
                ? _c(
                    "span",
                    {
                      class: _vm.question.cssClasses.number,
                      staticStyle: { position: "static" }
                    },
                    [_vm._v(_vm._s(_vm.question.no))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.question.isRequireTextBeforeTitle
                ? _c("span", { class: _vm.requiredTextCss }, [
                    _vm._v(_vm._s(_vm.question.requiredText))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("survey-string", {
                attrs: { locString: _vm.question.locTitle }
              }),
              _vm._v(" "),
              _vm.question.isRequireTextAfterTitle
                ? _c("span", { class: _vm.requiredTextCss }, [
                    _vm._v(_vm._s(_vm.question.requiredText))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showIcon,
                    expression: "showIcon"
                  }
                ],
                class: _vm.iconCss,
                attrs: {
                  tabindex: "0",
                  "aria-expanded": _vm.isCollapsed ? "false" : "true",
                  "aria-controls": !_vm.isCollapsed
                    ? _vm.question.contentId
                    : null
                },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.changeExpanded.apply(null, arguments)
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.question.cssClasses.panel.description },
            [
              _c("survey-string", {
                attrs: { locString: _vm.question.locDescription }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("survey-errors", { attrs: { question: _vm.question } }),
          _vm._v(" "),
          !_vm.isCollapsed
            ? _c(
                "div",
                {
                  class: _vm.question.cssClasses.panel.content,
                  style: { paddingLeft: _vm.question.innerPaddingLeft },
                  attrs: { id: _vm.question.contentId }
                },
                [
                  _vm._l(_vm.rows, function(row, index) {
                    return row.visible
                      ? _c(
                          "div",
                          {
                            key: _vm.question.id + "_" + index,
                            class: _vm.css.row
                          },
                          [
                            _c("survey-row", {
                              attrs: {
                                row: row,
                                survey: _vm.survey,
                                css: _vm.css
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _vm.question.hasEditButton
                    ? _c(
                        "div",
                        { class: _vm.question.cssClasses.panel.footer },
                        [
                          _c("input", {
                            class: _vm.survey.cssNavigationEdit,
                            attrs: {
                              type: "button",
                              value: _vm.survey.editText
                            },
                            on: { click: _vm.cancelPreview }
                          })
                        ]
                      )
                    : _vm._e()
                ],
                2
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }