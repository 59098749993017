
























































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { surveyCss } from "survey-core";
import { VueSurveyModel as SurveyModel } from "./surveyModel";
import { StylesManager, Base } from "survey-core";
import { BaseVue } from "./base";

@Component
export class Survey extends BaseVue {
  @Prop() survey: SurveyModel;
  processedCompletedHtmlValue: string;
  currentPageId: number = 1;
  get pageId() {
    return "page" + this.currentPageId.toString();
  }
  get navId() {
    return "nav" + this.currentPageId.toString();
  }

  forceUpdate() {
    this.$forceUpdate();
    this.currentPageId++;
  }

  constructor() {
    super();
  }
  protected getModel(): Base {
    return this.survey;
  }
  @Watch("survey")
  onPropertyChanged(value: string, oldValue: string) {
    this.onCreated();
    this.surveyOnMounted();
  }
  protected onMounted() {
    this.surveyOnMounted();
  }
  private surveyOnMounted() {
    if (!this.survey) return;
    Vue.set(this.survey, "currentPage", this.survey.currentPage);
    this.survey.onCurrentPageChanged.add((sender, options) => {
      this.currentPageId++;
    });
    this.survey.onPageVisibleChanged.add((sender, options) => {
      this.currentPageId++;
    });
    var el = this.$el;
    if (el) this.survey.doAfterRenderSurvey(el);
    this.survey.renderCallback = this.forceUpdate;
    this.survey.startTimerFromUI();
  }
  beforeDestroy() {
    this.survey.stopTimer();
    this.survey.renderCallback = undefined;
  }

  get hasTitle() {
    return !!this.survey.title && this.survey.showTitle;
  }
  get hasCompletedPage() {
    return this.survey.showCompletedPage && this.survey.state === "completed";
  }
  get css() {
    return this.survey.css;
  }
  getCompletedPageClasses() {
    var css = this.css;
    return css.body + " " + css.completedPage;
  }
  getProcessedCompletedHtml() {
    if (!this.hasCompletedPage) return "";
    if (!this.processedCompletedHtmlValue) {
      this.processedCompletedHtmlValue = this.survey.processedCompletedHtml;
    }
    return this.processedCompletedHtmlValue;
  }
  getCompletedStateClasses() {
    return this.css.saveData[this.survey.completedState];
  }
  start() {
    this.survey.start();
  }
  doTrySaveAgain() {
    this.survey.doComplete();
  }
}

// TODO: make this functionality available via surveyCss in all examples
Object.defineProperty(Survey, "cssType", {
  get: function() {
    return surveyCss.currentType;
  },
  set: function(newType) {
    StylesManager.applyTheme(newType);
  },
  enumerable: true,
  configurable: false,
});

Vue.component("survey", Survey);
export default Survey;
