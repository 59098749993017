var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sv-title-actions" },
    [
      _c(
        "span",
        { staticClass: "sv-title-actions__title" },
        [
          _vm.element.isPage
            ? _c("survey-string", {
                attrs: { locString: _vm.element.locTitle }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.element.isPage
            ? _c("survey-question-title-content", {
                attrs: { element: _vm.element, css: _vm.css }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("sv-action-bar", {
        staticClass: "sv-title-actions__bar",
        attrs: { items: _vm.items }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }