var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.model.isVisible,
          expression: "model.isVisible"
        }
      ],
      staticClass: "sv-popup",
      class: _vm.model.styleClass,
      on: {
        keydown: function(event) {
          _vm.model.trapFocus(event)
        },
        click: function() {
          _vm.model.clickOutside()
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "sv-popup__container",
          style: { left: _vm.model.left, top: _vm.model.top },
          on: { click: _vm.clickInside }
        },
        [
          _c("span", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.model.showPointer,
                expression: "model.showPointer"
              }
            ],
            staticClass: "sv-popup__pointer",
            style: {
              left: _vm.model.pointerTarget.left,
              top: _vm.model.pointerTarget.top
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "sv-popup__scrolling-content" }, [
            _c("div", { staticClass: "sv-popup__header" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "sv-popup__content" },
              [
                _c(
                  _vm.model.contentComponentName,
                  _vm._b(
                    { tag: "component" },
                    "component",
                    _vm.model.contentComponentData,
                    false
                  )
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.model.isModal,
                    expression: "model.isModal"
                  }
                ],
                staticClass: "sv-popup__footer"
              },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "sv-popup__footer-item sv-popup__button sv-popup__button--cancel",
                    on: {
                      click: function() {
                        _vm.model.cancel()
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.model.cancelButtonText) +
                        "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "sv-popup__footer-item sv-popup__button sv-popup__button--cancel",
                    on: {
                      click: function() {
                        _vm.model.apply()
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.model.applyButtonText) +
                        "\n        "
                    )
                  ]
                )
              ]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }