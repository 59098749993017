var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.question.cssClasses.root,
      style: {
        height: _vm.question.height + "px",
        width: _vm.question.width + "px"
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.question.needShowPlaceholder(),
              expression: "question.needShowPlaceholder()"
            }
          ],
          class: _vm.question.cssClasses.placeholder
        },
        [_vm._v("\n    " + _vm._s(_vm.question.placeHolderText) + "\n  ")]
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { class: _vm.question.cssClasses.controls }, [
        _c(
          "button",
          {
            class: _vm.question.cssClasses.clearButton,
            attrs: { type: "button", title: _vm.question.clearButtonCaption }
          },
          [_vm._v("\n      ✖\n    ")]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("canvas", { attrs: { tabindex: "0" } })])
  }
]
render._withStripped = true

export { render, staticRenderFns }