var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.hasHtml
        ? _c("div", { domProps: { innerHTML: _vm._s(_vm.customHtml) } })
        : _vm._e(),
      _vm._v(" "),
      _vm.hasDefaultRender
        ? _c(_vm.componentName, {
            tag: "component",
            attrs: { question: _vm.question, css: _vm.css }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }