var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasItems
    ? _c(
        "div",
        {
          ref: "container",
          staticClass: "sv-action-bar",
          on: {
            click: function(event) {
              event.stopPropagation()
            }
          }
        },
        _vm._l(_vm.wrappedItems, function(item) {
          return _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: item.visible || item.visible === undefined,
                  expression: "item.visible || item.visible === undefined"
                }
              ],
              key: item.id,
              staticClass: "sv-action",
              class: { "sv-action--hidden": !item.isVisible }
            },
            [
              item.needSeparator ? _c("sv-action-bar-separator") : _vm._e(),
              _vm._v(" "),
              _c(_vm.getComponentName(item), {
                tag: "component",
                attrs: { item: item }
              })
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }