var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { class: _vm.question.cssClasses.root }, [
    _vm.table.showHeader
      ? _c("thead", [
          _c(
            "tr",
            [
              _vm.question.allowRowsDragAndDrop ? _c("th") : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.table.headerRow.cells, function(cell) {
                return _c(
                  "th",
                  {
                    key: "header_" + cell.id,
                    class: _vm.question.cssClasses.headerCell,
                    style: { minWidth: cell.minWidth, width: cell.width }
                  },
                  [
                    cell.hasTitle
                      ? _c("survey-string", {
                          attrs: { locString: cell.locTitle }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !!cell.requiredText
                      ? _c("span", [_vm._v(_vm._s(cell.requiredText))])
                      : _vm._e()
                  ],
                  1
                )
              })
            ],
            2
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "tbody",
      _vm._l(_vm.table.rows, function(row) {
        return _c(
          "tr",
          { key: _vm.question.inputId + "_" + row.id, class: row.className },
          [
            _vm.question.allowRowsDragAndDrop
              ? _c("drag-drop-td", { attrs: { question: _vm.question } })
              : _vm._e(),
            _vm._v(" "),
            _vm._l(row.cells, function(cell, cellIndex) {
              return _c("survey-matrixcell", {
                key: row.id + "_" + cellIndex,
                attrs: { cell: cell, question: _vm.question }
              })
            })
          ],
          2
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.table.showFooter
      ? _c("tfoot", [
          _c(
            "tr",
            _vm._l(_vm.table.footerRow.cells, function(cell, cellIndex) {
              return _c("survey-matrixcell", {
                key: "footer_" + cellIndex,
                attrs: { cell: cell, question: _vm.question }
              })
            }),
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }