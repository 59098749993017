


















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Question } from "survey-core";

@Component
export class OtherChoice extends Vue {
  @Prop() question: Question;
  @Prop() commentClass: any;
  change(event: any) {
    this.question.comment = event.target.value;
  }
  keyup(event: any) {
    if (!this.question.isSurveyInputTextUpdate) return;
    this.question.comment = event.target.value;
  }
}
Vue.component("survey-other-choice", OtherChoice);
export default OtherChoice;
