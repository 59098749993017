var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.question.canRemovePanel && !_vm.question.isReadOnly
    ? _c("input", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isCollapsed,
            expression: "!isCollapsed"
          }
        ],
        class: _vm.question.getPanelRemoveButtonCss(),
        attrs: { type: "button", value: _vm.question.panelRemoveText },
        on: {
          click: function($event) {
            return _vm.removePanelClick(_vm.panel)
          }
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }