




















































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { default as QuestionVue } from "./question";
import { QuestionMatrixModel } from "survey-core";

@Component
export class Matrix extends QuestionVue<QuestionMatrixModel> {
  cellClick(row: any, column: any) {
    if (this.question.isInputReadOnly) return;
    row.value = column.value;
  }
}
Vue.component("survey-matrix", Matrix);
export default Matrix;
