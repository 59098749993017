var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    !_vm.question.isReadOnlyRenderDiv()
      ? _c("textarea", {
          class: _vm.question.cssClasses.other || _vm.commentClass,
          attrs: {
            readonly: _vm.question.isInputReadOnly,
            disabled: _vm.question.isInputReadOnly,
            maxlength: _vm.question.getOthersMaxLength(),
            placeholder: _vm.question.otherPlaceHolder,
            "aria-label": _vm.question.locTitle.renderedHtml
          },
          domProps: { value: _vm.question.comment },
          on: { change: _vm.change, keyup: _vm.keyup }
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.question.isReadOnlyRenderDiv()
      ? _c("div", [_vm._v(_vm._s(_vm.question.comment))])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }