




































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { default as QuestionVue } from "./question";
import { QuestionTextModel } from "survey-core";

@Component
export class QuestionText extends QuestionVue<QuestionTextModel> {
  change(event: any) {
    this.question.value = event.target.value;
  }
  keyup(event: any) {
    if (!this.question.isInputTextUpdate) return;
    this.question.value = event.target.value;
  }
  get inputStyle(): any {
    var style: any = {};
    if (!!this.question.inputWidth) {
      style.width = this.question.inputWidth;
    }
    return style;
  }
}
Vue.component("survey-text", QuestionText);
export default QuestionText;
