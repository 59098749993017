var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.css.progress }, [
    _c(
      "div",
      {
        class: _vm.css.progressBar,
        style: { width: _vm.progress },
        attrs: {
          role: "progressbar",
          "aria-valuemin": "0",
          "aria-valuemax": "100"
        }
      },
      [
        _c(
          "span",
          { class: _vm.css.progressText + " " + _vm.css.progressTextInBar },
          [_vm._v(_vm._s(_vm.survey.progressText))]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "span",
      { class: _vm.css.progressText + " " + _vm.css.progressTextUnderBar },
      [_vm._v(_vm._s(_vm.survey.progressText))]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }