var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("input", {
      class: _vm.question.getControlClass(),
      style: _vm.inputStyle,
      attrs: {
        disabled: _vm.question.isInputReadOnly,
        type: _vm.question.inputType,
        maxlength: _vm.question.getMaxLength(),
        min: _vm.question.renderedMin,
        max: _vm.question.renderedMax,
        step: _vm.question.renderedStep,
        size: _vm.question.inputSize,
        id: _vm.question.inputId,
        list: _vm.question.dataListId,
        placeholder:
          _vm.question.inputType === "range" || _vm.question.isReadOnly
            ? ""
            : _vm.question.placeHolder,
        autocomplete: _vm.question.autoComplete,
        "aria-required": _vm.question.isRequired,
        "aria-label": _vm.question.locTitle.renderedHtml,
        "aria-invalid": _vm.question.errors.length > 0,
        "aria-describedby":
          _vm.question.errors.length > 0 ? _vm.question.id + "_errors" : null
      },
      domProps: { value: _vm.question.value },
      on: { change: _vm.change, keyup: _vm.keyup }
    }),
    _vm._v(" "),
    _vm.question.dataListId
      ? _c(
          "datalist",
          { attrs: { id: _vm.question.dataListId } },
          _vm._l(_vm.question.dataList, function(item) {
            return _c("option", { domProps: { value: item } })
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }