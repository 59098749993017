















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Question } from "survey-core";
import {
  QuestionMatrixDropdownModelBase,
  QuestionMatrixDropdownRenderedTable,
} from "survey-core";
import { BaseVue } from "./base";
import { Base } from "survey-core";

@Component
export class MatrixTable extends BaseVue {
  @Prop() question: QuestionMatrixDropdownModelBase;
  get table(): QuestionMatrixDropdownRenderedTable {
    return this.question.renderedTable;
  }
  protected getModel(): Base {
    return this.question.renderedTable;
  }
}

Vue.component("survey-matrixtable", MatrixTable);
export default MatrixTable;
