var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "sv-action-bar-item",
      class: [
        { "sv-action-bar-item--active": _vm.item.isActive },
        _vm.item.innerCss
      ],
      attrs: {
        disabled: _vm.item.enabled !== undefined && _vm.item.enabled,
        title: _vm.item.tooltip || _vm.item.title
      },
      on: {
        click: function() {
          _vm.item.action()
        }
      }
    },
    [
      _vm.item.iconName
        ? _c("sv-svg-icon", {
            staticClass: "sv-action-bar-item__icon",
            attrs: { iconName: _vm.item.iconName, size: 24 }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.item.showTitle === undefined ||
      _vm.item.showTitle ||
      !_vm.item.iconName
        ? _c(
            "span",
            {
              staticClass: "sv-action-bar-item__title",
              class: {
                "sv-action-bar-item__title--with-icon": !!_vm.item.iconName
              }
            },
            [_vm._v(_vm._s(_vm.item.title))]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }