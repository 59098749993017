var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.question.cssClasses.root }, [
    _vm.question.contentMode === "image"
      ? _c("img", {
          class: _vm.question.cssClasses.image,
          style: { objectFit: _vm.question.imageFit },
          attrs: {
            src: _vm.question.imageLink,
            alt: _vm.question.text || _vm.question.title,
            width: _vm.question.imageWidth
              ? _vm.question.imageWidth + "px"
              : undefined,
            height: _vm.question.imageHeight
              ? _vm.question.imageHeight + "px"
              : undefined
          }
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.question.contentMode === "video"
      ? _c("embed", {
          class: _vm.question.cssClasses.image,
          style: { objectFit: _vm.question.imageFit },
          attrs: {
            src: _vm.question.imageLink,
            width: _vm.question.imageWidth
              ? _vm.question.imageWidth + "px"
              : undefined,
            height: _vm.question.imageHeight
              ? _vm.question.imageHeight + "px"
              : undefined
          }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }