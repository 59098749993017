


























import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { ActionBar, IActionBarItem, AdaptiveActionBarItemWrapper } from "survey-core";

export * from "./action-bar-item.vue";
export * from "./action-bar-item-dropdown.vue";
export * from "./action-bar-separator.vue";

@Component
export class ActionBarViewModel extends Vue {
  @Prop() items: Array<IActionBarItem>;
  @Prop() handleClick: boolean;
  private model = new ActionBar();

  constructor() {
    super();
    this.model.setItems(this.items);
  }

  get wrappedItems(): AdaptiveActionBarItemWrapper[] {
    return this.model.items;
  }

  getComponentName(item: any) {
    return item.component || "sv-action-bar-item";
  }

  get hasItems() {
    return (this.model.items || []).length > 0;
  }
}

Vue.component("sv-action-bar", ActionBarViewModel);
export default ActionBarViewModel;
