




































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { SurveyModel } from "survey-core";
import { IElement } from "survey-core";
import { Question } from "survey-core";

@Component
export class ElementHeader extends Vue {
  @Prop() element: IElement;
  @Prop() css: any;

  beforeDestroy() {
    this.element.stateChangedCallback = null;
  }

  getTitleClass(element: Question) {
    return element.cssTitle;
  }
  getHeaderClass(element: Question) {
    return element.cssHeader;
  }
}
Vue.component("survey-element-header", ElementHeader);
export default ElementHeader;
